import React, {useEffect} from 'react'
import { useAuth } from '../resources/AuthContext'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import supabase from '../resources/Supabase'

const Login = ({onSuccess=null}) => {
    const { session, setSession, hasUser, userId, signOut } = useAuth();

    // when successfully logged in, call the onSuccess function
    useEffect(() => {
        if (session && onSuccess) {
            onSuccess();
        }
    }, [session])

    if (!session) {
        return (
            <div className='standard-width'>
                <div className='auth-container'>
                    <br />
                    <br />

                    <Auth 
                        supabaseClient={supabase} 
                        localization={{
                            variables: {
                            sign_in: {
                                email_label: 'Email',
                                password_label: 'Password',
                                email_input_placeholder: 'Email',
                                password_input_placeholder: 'Password',
                                button_label: 'Log In',
                            },
                            sign_up: {
                                email_label: 'Email',
                                password_label: 'Password',
                                email_input_placeholder: 'Email',
                                password_input_placeholder: 'Password',
                                button_label: 'Ready to check your vibe?',
                            },
                            },
                        }}
                        appearance={{
                            theme: ThemeSupa,
                            variables: {
                            default: {
                                colors: {
                                brand: '#333',
                                brandAccent: '#000',
                                },
                            },
                            },
                        }}
                        providers={[]}
                    />
                </div>
            </div>
        )
    }
    else {
        if (onSuccess) {
            onSuccess();
            return null;
        }

        return (
            <div className='standard-page-container'>
                <h1>Logged in!</h1>
                <h4>Session: {session.user.email}</h4>
                <h4>UserId: {userId}</h4>
                <button onClick={signOut}>Sign Out</button>
            </div>
        )
    }
    
}

export default Login;
