// Exportable object with methods to interact with Supabase

import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://evyuldxahonlrlcofupv.supabase.co'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImV2eXVsZHhhaG9ubHJsY29mdXB2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjE5NjQxNjksImV4cCI6MjAzNzU0MDE2OX0.KMrE1o4Z7VU_w39a5NuxXX-bcn-SaJJjn-4bqVCQX7o'

const supabase = createClient(supabaseUrl, supabaseKey)


// Images can be accessed at: https://[project_id].supabase.co/storage/v1/object/public/[bucket]/[asset-name]
// I am going to use the uuid of the entry in the photos table as the asset-name
// the bucket is photos


export default supabase
