// Component that shows an optimized Hinge profile for the user.
// It just wraps a Gallery component that shows the assessedPhotos passed into it.

import Gallery from './Gallery';
import OverallScore from './subcomponents/OverallScore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const OptimizedProfile = ({ assessedPhotos, averageScore, optimalAverageScore, is_male }) => {
    const improvement = (optimalAverageScore - averageScore).toFixed(1);

    const messageFrequency = ({percentile, male=is_male}) => {
        const a = male ? 74 : 1.01425E+17
        const b = male ? 9 : 4.1
        const c = male ? -11 : -40
        const d = male ? 1 : 0

        const y = a * Math.exp(b*percentile + c) + d	

        return y
    }

    const getImprovementInMessageFrequency = ({scoreBefore, scoreAfter}) => {
        const percentileBefore = scoreBefore / 10
        const percentileAfter = scoreAfter / 10
        const messageFrequencyBefore = messageFrequency({percentile: percentileBefore})
        const messageFrequencyAfter = messageFrequency({percentile: percentileAfter})
        const improvementInMessageFrequency = ((messageFrequencyAfter/messageFrequencyBefore-1)*100).toFixed(0)

        return improvementInMessageFrequency
    }

    return (
        <div className='optimized-profile-container'>
            {averageScore && optimalAverageScore && 
                <div className='optimized-profile-score-improvement-container'>
                    <h3>Score Improvement</h3>
                    <br />
                    <div className="overall-score-change-container">
                        <div className="score-before-after-container">
                            <OverallScore score={averageScore} preRounded={true}  color='#d9d9d9'/>
                            <h6>Average Photo</h6>
                        </div>
                        <div className="arrow-container">
                            <ArrowForwardIcon />
                        </div>
                        <div className="score-before-after-container" >
                            <OverallScore score={optimalAverageScore} preRounded={true}/>
                            <h6 style={{fontWeight: '800'}}>Optimized Profile</h6>
                        </div>
                    </div>

                    <br />
                    {improvement > 0.2 && 
                    <div className='improvement-message-container'>
                        <h4>On average, your scores improved by <strong>{improvement}</strong> points. Congrats, this is a great result!</h4>
                        <h4>Research suggests that this will result in about a <strong>{getImprovementInMessageFrequency({scoreBefore: averageScore, scoreAfter: optimalAverageScore})}%</strong> increase in matches.</h4>
                    </div>}
                    <br />
                    <br />
                    <h3 style={{fontWeight: '700'}}>Optimized Profile</h3>
                    <br />
                    <div className='improvement-message-container'>
                        <h4>Here are the six optimal photos we picked out for you!</h4>
                    </div>
                    {/* <br /> */}
                </div>}

            {assessedPhotos && <Gallery assessments={assessedPhotos} showLikes={false}/>}

        </div>
    );
}

export default OptimizedProfile;