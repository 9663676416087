import React, { useState } from 'react';
import RatingTag from './subcomponents/RatingTag';
import OverallScore from './subcomponents/OverallScore';
import LikeCount from './subcomponents/LikeCount';
import supabase from '../resources/Supabase';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';

const AssessmentCard = ({ assessedPhoto, isLive, showLikes=true }) => {
    // console.log('assessedPhoto:', assessedPhoto);

    const [expanded, setExpanded] = useState(false);
    const [publicStatus, setPublicStatus] = useState('public');
    
    const debugMode = false;

    function toTitleCase(sentence) {
        const exceptions = ["and"];
        return sentence.toLowerCase().split(' ').map(function(word, index) {
            if (exceptions.includes(word) && index !== 0) {
                return word;
            }
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
    }

    const handleDeletePhoto = async () => {
        const { data, error } = await supabase.from('photos').delete().eq('url', assessedPhoto.url);
        console.log('data:', data);
        console.log('error:', error);
    }

    const switchPrivacy = async () => {
        const newStatus = publicStatus === 'public' ? 'private' : 'public';
        const { data, error } = await supabase.from('photos').update({ privacy: newStatus }).eq('url', assessedPhoto.url);
        console.log('data:', data);
        console.log('error:', error);
        setPublicStatus(newStatus);
    }

    const addIsCeleb = async () => {
        const assessment = assessedPhoto.assessment;
        assessment.is_celebrity = 100;
        const { data, error } = await supabase.from('photos').update({ assessment: assessment }).eq('url', assessedPhoto.url);
        console.log('data:', data);
        console.log('error:', error);
    }

    return (
        <div className='assessment-card'>
            {assessedPhoto.url && (
                <img 
                    src={assessedPhoto.img ? assessedPhoto.img.src : assessedPhoto.url} 
                    alt="Assessment" 
                    className="assessment-photo" 
                    onClick={() => setExpanded(true)}
                />
            )}
            <div className='overall-score-container'>
                <OverallScore score={assessedPhoto.assessment.overall_impression} />
            </div>
            <div className='assessment-section' >
                <br/>
                <div className='feedback-section' >
                    <h4 className='feedback-title'>Feedback</h4>
                    <div className='feedback'>{assessedPhoto.assessment.comments}</div> 
                </div>

                <br />
                <div className='rating-section'>
                    <div className='column'>
                        <RatingTag name="Attractiveness" score={assessedPhoto.assessment.overall_attractiveness} />
                        <RatingTag name="Physique" score={assessedPhoto.assessment.physique} />
                        <RatingTag name="Face" score={assessedPhoto.assessment.facial_attractiveness} />
                        
                        {/* <RatingTag name="Humor" score={assessedPhoto.assessment.humor} />
                        <RatingTag name="Skin" score={assessedPhoto.assessment.skin_condition} />
                        <RatingTag name="BMI" score={assessedPhoto.assessment.bmi*10} />
                        <RatingTag name="People" score={assessedPhoto.assessment.number_of_people*10} />
                        <RatingTag name="Faces" score={assessedPhoto.assessment.number_of_faces*10} /> */}


                    </div>

                    <div className='column'>
                        <RatingTag name="Style" score={assessedPhoto.assessment.style} />
                        <RatingTag name="Grooming" score={assessedPhoto.assessment.grooming} />
                        <RatingTag name="Hairstyle" score={assessedPhoto.assessment.hairstyle} />
                        
                        {/* <RatingTag name="Social" score={assessedPhoto.assessment.social_status} />
                        <RatingTag name="Authenticity" score={assessedPhoto.assessment.authenticity} />
                        <RatingTag name="Height" score={assessedPhoto.assessment.estimated_height*10} unit="in" />
                        <RatingTag name="Weight" score={assessedPhoto.assessment.estimated_weight*10} unit="lbs" /> */}
                    </div>
                </div>
                <br/>
                <div className='impression-section'>
                    <h4 className='impression-title'>It's giving...</h4>
                    <div className='impression'>{toTitleCase(assessedPhoto.assessment.personality_description)}</div>
                </div>

                <br/>
                <div className='impression-section'>
                    <h4 className='impression-title'>Celebrity Lookalike</h4>
                    <div className='impression'>{assessedPhoto.assessment.celebrity_lookalike}</div>
                </div>

                {isLive && showLikes && <LikeCount photo={assessedPhoto} />}
                {debugMode && <button onClick={handleDeletePhoto}>Delete Photo</button>}
                {!isLive && <br/>}
                {!isLive && <button onClick={switchPrivacy} className='privacy-button'>
                    {publicStatus === 'private' ? (
                        <VisibilityOffIcon style={{ marginRight: '5px' }} />
                    ) : (
                        <VisibilityIcon style={{ marginRight: '5px' }} />
                    )}
                    {publicStatus.toUpperCase()}
                </button>}
                {debugMode && <button onClick={addIsCeleb}>Is Celeb</button>}
            </div>

            {expanded && 
                <div className="modal" onClick={() => setExpanded(false)}>
                    <div className="modal-content" > {/* onClick={(e) => e.stopPropagation()} */}
                        {/* <div className="close-button-container">
                            <CloseIcon 
                                className="close-button" 
                                onClick={() => setExpanded(false)}
                            />
                        </div> */}
                        <img src={assessedPhoto.url} alt="Full size" className="modal-image" />
                    </div>
                </div>

            }

        </div>
    )
}

export default AssessmentCard;
