import supabase from '../../resources/Supabase';
import React, { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';


const LikeCount = ({ photo }) => {

    const [liked, setLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(photo.likes);
    
    const updateLikeCount = async () => {
        // if liked 
        if (liked) {
            setLiked(false);
            setLikeCount(likeCount - 1);
            const { data, error } = await supabase.from('photos').update({ likes: photo.likes - 1 }).eq('url', photo.url);
            console.log('data:', data);
            console.log('error:', error);
        } else {
            setLiked(true);
            setLikeCount(likeCount + 1);
            const { data, error } = await supabase.from('photos').update({ likes: photo.likes + 1 }).eq('url', photo.url);
            console.log('data:', data);
            console.log('error:', error);
        }
    }
    
    useEffect(() => {
        setLiked(photo.liked);
        setLikeCount(photo.likes);
    }, [photo]);

    return (
        <Tooltip title={liked ? "Unlike" : "Like"}>
            <IconButton
                onClick={() => {
                    updateLikeCount();
                }}
                color={liked ? "error" : "default"}
                aria-label="like"
            >
                <FavoriteIcon />
                <span style={{ marginLeft: '5px' }}>{likeCount}</span>
            </IconButton>
        </Tooltip>
    );

};

export default LikeCount;