import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PhotoUploader from './components/PhotoUploader';
import GalleryScene from './scenes/GalleryScene';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import Navbar from './components/Navbar';
import CheckoutComplete from './scenes/CheckoutComplete';
import { AuthProvider } from './resources/AuthContext';
import LoginForm from './components/LoginForm';
import UserImages from './scenes/UserImages';

// Create a separate component for the home page
function Home() {
  return <h1>Hello World</h1>;
}

function App() {
  ReactGA.initialize('G-DE0HTS5RD9');

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="App">
        <AuthProvider>
            <BrowserRouter>
                <Navbar />
                <Routes>
                    {/* <Route path="/" element={<Home />} /> */}
                    <Route path="/upload" element={<PhotoUploader />} />
                    <Route path="/gallery" element={<GalleryScene />} />
                    <Route path="/private" element={<GalleryScene privacy_status='private' />} />
                    <Route path="/all" element={<GalleryScene privacy_status='all' />} />
                    <Route path="/" element={<GalleryScene />} />
                    <Route path="/checkout-complete/:session_id" element={<CheckoutComplete/>} />
                    <Route path="/login" element={<LoginForm />} />
                    <Route path="/user-images/:user_id" element={<UserImages />} />
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    </div>
  );
}

export default App;