// Scene that shows a Gallery with all the assessedPhotos in the photos table for a given user_id

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import supabase from '../resources/Supabase';
import Gallery from '../components/Gallery';
import OptimizedProfile from '../components/OptimizedProfile';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const UserImages = () => {
    const [assessedPhotos, setAssessedPhotos] = useState([]);
    const [optimalPhotos, setOptimalPhotos] = useState([]);
    const { user_id } = useParams();

    const [optimalAverageScore, setOptimalAverageScore] = useState(0);
    const [averageScore, setAverageScore] = useState(0);
    const [showAll, setShowAll] = useState(false);
    const [is_male, setIsMale] = useState(true);

    useEffect(() => {
        const fetchAssessedPhotos = async () => {
            const { data, error } = await supabase.from('photos').select('*').eq('user_id', user_id);
            setAssessedPhotos(data);
        }

        const fetchOptimalPhotos = async () => {
            const { data, error } = await supabase.from('optimized_profiles').select('is_male, assessedPhotos:profile_components(photos(*), priority)').eq('user_id', user_id).order('created_at', { ascending: false });
            setIsMale(data[0].is_male);
            // flatten data[0].assessedPhotos to extract 'photos' from each
            const flat = data[0].assessedPhotos.map(photo => ({ ...photo.photos, priority: photo.priority })).flat();
            // order flat by priority
            flat.sort((a, b) => a.priority - b.priority);
            console.log(flat);
            setOptimalPhotos(flat);
        }

        fetchAssessedPhotos();
        fetchOptimalPhotos();

    }, [user_id]);

    useEffect(() => {
        if (assessedPhotos.length > 0) {
            const totalScore = assessedPhotos.reduce((sum, photo) => {
                return sum + (photo.assessment?.overall_impression || 0);
            }, 0);
            setAverageScore((Math.floor(totalScore / assessedPhotos.length) / 10).toFixed(1));
        }

        if (optimalPhotos.length > 0) {
            const optimalTotalScore = optimalPhotos.reduce((sum, photo) => {
                return sum + (photo.assessment?.overall_impression || 0);
            }, 0);
            setOptimalAverageScore((Math.ceil(optimalTotalScore / optimalPhotos.length) / 10).toFixed(1));
        }
    }, [assessedPhotos, optimalPhotos]);


    return (
        <div>
            {optimalPhotos.length > 0 && <OptimizedProfile assessedPhotos={optimalPhotos} averageScore={averageScore} optimalAverageScore={optimalAverageScore} is_male={is_male} />}
            
            {assessedPhotos.length > 0 && 
                <div className='gallery-container'>
                    <div className='gallery-header'>
                        <h4>Curious to see how the rest of your photos were reviewed? Click below to view all of them.</h4>
                        <br />
                        <button className='view-all-button' onClick={() => setShowAll(!showAll)}>
                            {showAll ? <RemoveCircleOutlineIcon fontSize="medium" /> : <AddCircleOutlineIcon fontSize="medium" />}
                            {showAll ? 'Hide' : 'View All Photos'}
                        </button>
                    </div>
                    {showAll && <div className='gallery-container'>
                        <h1>All Photos</h1>
                        <Gallery assessments={assessedPhotos} />
                    </div>}
                    <br />
                </div>
            }
        </div>
    );
}

export default UserImages;