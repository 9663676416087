import React, { useState, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import supabase from '../resources/Supabase';
import AssessmentCard from './AssessmentCard';
import CircularProgress from '@mui/material/CircularProgress';
import Gallery from './Gallery';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const PhotoUploader = () => {
    const [photos, setPhotos] = useState([]);
    const fileInputRef = useRef(null);
    const [assessments, setAssessments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hadError, setHadError] = useState(false);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const lowercaseFiles = files.map(file => {
            const fileNameParts = file.name.split('.');
            const extension = fileNameParts.pop().toLowerCase();
            const newFileName = `${fileNameParts.join('.')}.${extension}`;
            console.log('newFileName:', newFileName);
            return new File([file], newFileName, { type: file.type });
        });
        setPhotos(prevPhotos => [...prevPhotos, ...lowercaseFiles]);
    };

    const handleRemovePhoto = (indexToRemove) => {
        console.log('Removing photo at index:', indexToRemove);
        console.log('Current photos:', photos);
        setPhotos(prevPhotos => {
            const newPhotos = prevPhotos.filter((_, index) => index !== indexToRemove);
            console.log('New photos:', newPhotos);
            return newPhotos;
        });
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleSubmit = async () => {
        setLoading(true);
        console.log('photos:', photos);

        // upload the photos to supabase storage ('photos')
        // path is the name of the file
        const upload_photos = async () => {
            const uploadPromises = photos.map(photo => {
                let name = crypto.randomUUID() + '.' + photo.name.split('.').pop();
                
                return supabase.storage.from('photos').upload(name, photo)
                    .then(data => data.data.path);
            });

            return Promise.all(uploadPromises)
                .then(photo_ids => {
                    return photo_ids.map(id => `https://evyuldxahonlrlcofupv.supabase.co/storage/v1/object/public/photos/${id}`);
                });
        }
        
        upload_photos().then(photo_urls_with_base => {
            console.log('photo_urls_with_base:', photo_urls_with_base);
            const photos = photo_urls_with_base.map(url => ({ url: url}));
            // axios.post('http://localhost:8000/grade_photos', 
            axios.post('https://hingehelper-api.vercel.app/grade_photos', 
                { 'photos': photos })
                .then(response => {
                    console.log('response:', response.data);
                    const assessed_photos = response.data.map(photo =>
                        ({
                            url: photo.url,
                            assessment: photo.assessment,
                            user_id: null
                        })
                    )
                    console.log('assessed_photos:', assessed_photos);
                    setAssessments([...assessed_photos, ...assessments]);
                    setLoading(false);
                    setHadError(false);
                    setPhotos([]);
                    // upsert to supabase
                    supabase.from('photos').upsert(assessed_photos).select().then(data => {
                        console.log('Supabase upsert data:', data);
                    });
                })
                .catch(error => {
                    console.error('Error grading photos:', error);
                    setHadError(true);
                    setLoading(false);
                });
        });

    };

    return (
        <div className='photo-uploader-page'>
            <div className="upload-container">
                <br />
                <input
                        ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(event) => handleFileChange(event)}
                    style={{ display: 'none' }}
                />
                <button onClick={handleUploadClick} className="upload-button">
                    <FileUploadIcon />
                    Upload Photos
                </button>
                <br/>
                <div className="photo-grid">
                    {photos.map((photo, index) => (
                        <div key={index} className="photo-container">
                            <img
                                src={URL.createObjectURL(photo)}
                                alt={`Uploaded ${index + 1}`}
                                className="uploaded-photo"
                            />
                            <CloseIcon
                                className="remove-icon"
                                onClick={() => handleRemovePhoto(index)}
                            />
                        </div>
                    ))}
                </div>
                <br />
                {photos.length > 0 && <button className='upload-submit-button' onClick={handleSubmit}>Submit</button>}


            </div>


            {loading && <div className="loading-container">
                <CircularProgress />
            </div>}
            {hadError && <div className="error-container">
                <h1>Submission Failed</h1>
                <p>There was an error processing your submission. Please try again.</p>
                <p>Errors most commonly occur when you have submitted inappropriate content or a photo of a famous person.</p>
                <p>Incorrect image formats can also cause issues. Use a .jpg, .jpeg, or .png file for best results.</p>

            </div>}
            {!loading && assessments.length > 0 &&
                <div className='results-section'>
                    <h1>Results!</h1>
                    <Gallery assessments={assessments} isLive={false}/>

                </div>
            }

            <div className='footer'>
                <p><i>By using the site, you agree to our <a href='/terms.pdf' target='_blank'>Terms of Service</a></i>.</p>
            </div>
        </div>
    );
};

export default PhotoUploader;