import React from 'react';
import { useParams } from 'react-router-dom';

const CheckoutComplete = () => {
    const { session_id } = useParams();

    return (
        <div>
            <h1>Checkout Complete</h1>
            <p>Session ID: {session_id}</p>
        </div>
    );
};

export default CheckoutComplete;
