import React, { useState, useEffect } from 'react';
import AssessmentCard from './AssessmentCard';
import supabase from '../resources/Supabase';

const Gallery = ({ assessments, isLive=true, showLikes=true }) => {

    return (
        <div className='gallery'>
            {assessments.map((assessment) => (
                <AssessmentCard key={assessment.id} assessedPhoto={assessment} isLive={isLive} showLikes={showLikes}/>
            ))}
        </div>
    )
}

export default Gallery;