// basic navbar - for now, just a home button
import MenuIcon from '@mui/icons-material/Menu';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
    const [currentRoute, setCurrentRoute] = useState('');
    const location = useLocation();
    const [showUploadButton, setShowUploadButton] = useState(false);

    useEffect(() => {
        setCurrentRoute(location.pathname);
        if (location.pathname === '/upload') {
            setShowUploadButton(false);
        }
        else {
            setShowUploadButton(true);
        }
    }, [location]);

    return (
        <div className="navbar">
            <div className='navbar-left'>
                <button className="home-button" onClick={() => window.location.href = '/'}>
                    <div className="logo">
                        <img src="/logo192.png" alt="logo" />
                    </div>
                    vibecheck.fyi
                </button>
            </div>
            <div className='navbar-right'>
                {showUploadButton && (
                    <button className="upload-route-button" onClick={() => window.location.href = '/upload'}>
                        See Your Rating
                    </button>
                )}
                {/* {!showUploadButton && (
                    <button className="profile-button">
                        <MenuIcon />
                    </button>
                )} */}
            </div>
        </div>
    );
};

export default Navbar;