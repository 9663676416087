// src/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import supabase from './Supabase'

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [session, setSession] = useState(null)
    const hasUser = session ? true : false;
    const [userId, setUserId] = useState(null);

    const userSetup = async () => {
        const { data: { user } } = await supabase.auth.getUser()
        if (user) {
            setUserId(user.id)
        }
    }

    useEffect(() => {
        userSetup()
    }, [userId, session])


    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })

        return () => subscription.unsubscribe()
    }, [])

    const signOut = async () => {
        await supabase.auth.signOut();
        setSession(null);
        setUserId(null);
    }


    return (
        <AuthContext.Provider value={{ session, setSession, hasUser, userId, signOut }}>
            {children}
        </AuthContext.Provider>
    );

};

export const useAuth = () => useContext(AuthContext);
