// rating tag just shows a name and a score
// the score will be colored differently depending on its value: adds a class "low", "medium", or "high"

const RatingTag = ({ name, score }) => {
    const scoreOutOfTen = score / 10;
    let color;
    if (scoreOutOfTen < 5.5) {
        color = "low";
    } else if (scoreOutOfTen < 7) {
        color = "medium";
    } else {
        color = "high";
    }
    
    return (
        <div className={`rating-tag`}>
            <div className="rating-tag-name">{name}</div>
            <div className={`rating-tag-score ${color}`}>
                {scoreOutOfTen.toFixed(1)}
            </div>
        </div>
    );
};

export default RatingTag;