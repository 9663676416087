// scene that gets ALL assessments and photos from supabase, then shows them
// This is a tool for diagnostics

import React, { useEffect, useState, useRef } from 'react';
import supabase from '../resources/Supabase';
import Gallery from '../components/Gallery';

const GalleryScene = ({privacy_status='public'}) => {
    const [assessments, setAssessments] = useState([]);
    const [sortedAssessments, setSortedAssessments] = useState([]);
    const [sortType, setSortType] = useState('likes');
    const [isOpen, setIsOpen] = useState(false);
    const [sortOptions, setSortOptions] = useState(['likes', 'rating', 'attractiveness', 'new']);
    const dropdownRef = useRef(null);
    const [filterCelebs, setFilterCelebs] = useState(false);

    const filterAssessments = (assessments) => {

        const assessmentsCleaned = assessments.map(assessment => {
            const img = new Image();
            img.src = assessment.url;
            return {
                ...assessment,
                img: img
            };
        });

        if (filterCelebs) {
            return assessmentsCleaned.filter(assessment => 
                !assessment.assessment.hasOwnProperty('is_celebrity') || 
                assessment.assessment.is_celebrity <= 90
            );
        }
        return assessmentsCleaned;
    }

    useEffect(() => {
        const fetchData = async () => {
            if (privacy_status === 'public') {
                const { data, error } = await supabase
                    .from('photos')
                    .select('*')
                    .or('privacy.neq.private,privacy.is.null')
                    .order('likes', { ascending: false });
                setAssessments(filterAssessments(data));

            } else if (privacy_status === 'all') {
                const { data, error } = await supabase.from('photos').select('*').order('likes', { ascending: false });
                setAssessments(filterAssessments(data));

            }
            else if (privacy_status === 'private') {
                const { data, error } = await supabase.from('photos').select('*').eq('privacy', 'private').order('likes', { ascending: false });
                setAssessments(filterAssessments(data));
            }
        };
        fetchData();
    }, [privacy_status, filterCelebs]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // sort by likes, rating, attractiveness, new
    useEffect(() => {
        console.log(assessments);
        // if sort type is 'attractiveness', then sort by 'assessment.overall_attractiveness'
        // if sort type is 'rating', then sort by 'assessment.overall_impression'
        // if sort type is 'likes', then sort by 'likes'
        // if sort type is 'new', then sort by 'created_at'
        const sorted = [...assessments].sort((a, b) => {
            if (sortType === 'attractiveness') {
                const attractivenessDiff = b.assessment?.overall_attractiveness - a.assessment?.overall_attractiveness;
                return attractivenessDiff !== 0 ? attractivenessDiff : (b.likes - a.likes);
            } else if (sortType === 'rating') {
                return b.assessment?.overall_impression - a.assessment?.overall_impression;
            } else if (sortType === 'likes') {
                return b.likes - a.likes;
            } else if (sortType === 'new') {
                return new Date(b.created_at) - new Date(a.created_at);
            }
            return 0;
        });
        setSortedAssessments(sorted);

    }, [sortType, assessments]);

    return (
        <>  
            <br/>
            {/* <div className="split">
                <button onClick={() => {
                    window.location.href = '/upload';
                }}>
                    Upload
                </button>
            </div> */}
            <div className='sort-section'>
                <div className="custom-dropdown" ref={dropdownRef}>
                    <button className="dropdown-button" onClick={() => setIsOpen(!isOpen)}>
                        Sort by: {sortType.charAt(0).toUpperCase() + sortType.slice(1)}
                    </button>
                    {isOpen && (
                        <div className="dropdown-menu">
                            {sortOptions.map((option) => (
                                <div 
                                    key={option} 
                                    className="dropdown-item"
                                    onClick={() => {
                                        setSortType(option);
                                        setIsOpen(false);
                                    }}
                                >
                                    {option.charAt(0).toUpperCase() + option.slice(1)}
                                </div>
                            ))}
                        </div>
                    )}
                    
                </div>
                <label className="checkbox-container">
                    <input type="checkbox" checked={filterCelebs} onChange={() => setFilterCelebs(!filterCelebs)} />
                    Filter out celebrities
                </label>
 
            </div>
            <Gallery assessments={sortedAssessments}/>
        </>
    );
}

export default GalleryScene;


