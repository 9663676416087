const OverallScore = ({ score, preRounded=false, color }) => {
    let scoreOutOfTen = preRounded ? score : (score / 10).toFixed(1);
    return (
        <div className={`overall-score ${scoreOutOfTen < 4.5 ? 'low' : scoreOutOfTen < 7.0 ? 'medium' : scoreOutOfTen < 8.5 ? 'high' : 'very-high'}`} style={{backgroundColor: color}}>
            <div className='score'>
                {scoreOutOfTen}
            </div>
        </div>
    );
};

export default OverallScore;